const color = {
  white: "#FFF",
  grayClearOne: "#D5D5D5",
  grayClear: "#868080",
  grayOne: "#878787",
  gray: "#5f5b5b",
  purple: "#B06FF1",
  purpleDark: "#9F5FDE",
  pink: "#F270FC",
  blue: "#336AE9",
  red: "#ED5A68",
  green: "#28A138",
  blueClear: "#dcefed",
  colorCard: "#F5F8FF",
  colorsBaseProductNormalActive: "#336AE9",
  colorBlueClean: "#A3C8FC",
  colorPrimary: "#2E62AC",
  colorSecondary: "#EA553C",
  colorThird: "#FCBF52",
  colorFourth: "#89A3C9",
  colorNavyBlue: "#24234F",
  colorCardOrange: "#FCAD09",
  colorsBaseMenu: "#fafafe",
  colorBorderCard: "#DBE6FF",
  colorsBaseInkLight: "#5F738C",
colorsBaseProductLightActive: "#ccd0ee",
};

export default color;
